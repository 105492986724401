const menu = {
    "menu.dashboard": "Dashboard",
    "menu.sales": "Sales",
    "menu.customers": "Customers",
    "menu.salesorder": "Sales order",
    "menu.invoicing": "Invoicing",
    "menu.app": "Home",
    "menu.core": "Core Settings",
    "menu.organisations": "Organisations",
    "menu.edit": "Edit",
    "menu.add": "Add",
    "menu.view": "View",
    "menu.users": "Users",
};
export default menu;