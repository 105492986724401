import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {login} from '../../helpers/authUtils'

const initialState = {
    id: '',
    name: '',
    key: '',
    available: false,
    user_roles: [],
    user_access: [],
    key_access: [],
    user_key_access: [],
    email_support: '',
    custom_fields: null,
    settings: null,
    registration_blocked: true,
    currency: "CAD",
    language: "en",
    languages: [],
    timezone: 0,
    country: 'CA',
    logo_url: ''
}

export const organisationAsync = createAsyncThunk(
    'org/organisationAsync',
    async (payload, { rejectWithValue }) => {
        try {
            return await login(payload);
        } catch (err) {
            return rejectWithValue([], err);
        }
    }
);

export const orgSlice = createSlice({
    name: 'org',
    initialState,
    reducers: {
        setOrganisation: (state, action) => {
            if(action.payload){
                return action.payload
            }
        },
    },
    extraReducers: {
        [organisationAsync.fulfilled]: (state, action) => {
            const {payload} = action;
            if(payload.status === 200){
                return {
                    ...state,
                    ...payload.data,
                    isAuthenticated : true,
                    login_status: payload.status,
                    login_loading: false
                }
            }
            if(payload.status === 401){
                return {
                    ...initialState,
                    login_status: payload.status,
                    login_message: payload.error
                }
            }
        },
        [organisationAsync.rejected]: (state, action) => {
            console.log(action);
        },
    },
});


export const { setOrganisation } = orgSlice.actions;

export default orgSlice.reducer;