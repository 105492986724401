const env = process.env;

export const settings = {
    app_id: env.REACT_APP_ID || false,
}
export const api = {
    root_url: env.REACT_APP_API_URL,
    url: env.REACT_APP_API_URL + "/api/v1/",
};
export const app = {
    version: env.REACT_APP_VERSION || '',
    name: env.REACT_APP_NAME || 'ExoStack',
    description: env.REACT_APP_DESCRIPTION || '',
    google_map_api_key: env.REACT_APP_MAP_KEY || '',
};
