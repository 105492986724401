import axios from "axios";
import jwt_decode from "jwt-decode";
import localforage from "localforage";
import {coreModuleLoaded, setOrganisationState} from "./core";
import {settings, api} from "../config/config";

export const login = async (payload) => {
    return await axios({
        method: 'post',
        headers: {
            'Application': settings.app_id
        },
        url: api.url+'auth/login',
        data: {
            ...payload,
            use_app_client: true
        },
    })
        .then(response => {
            const { auth_token, refresh_token, session } = response.data;
            const user = jwt_decode(auth_token);
            localforage.setItem('refresh_token', refresh_token);
            const isSuperAdmin = user.realm_access.roles.find(x => x === 'super_admin') === 'super_admin';
            const updatedUser = {status: response.status, data: {
                    token: auth_token,
                    locale: user.locale,
                    firstname: user.given_name,
                    lastname: user.family_name,
                    email: payload.email,
                    organisation_id: user.organisationId,
                    refresh_token,
                    isSuperAdmin
                }}
            _validateSession(session);
            return updatedUser;
        })
        .catch(error => {
            return {status: error.response.status, error: error.response.data.error}
        })
};

export const validateSession = async (user) => {

    const refresh_token = await localforage.getItem('refresh_token');
    let is_authenticated = false
    let updateUser = [];

    if(refresh_token){

        const {exp} = jwt_decode(refresh_token);

        if (exp > (new Date().getTime() + 1) / 1000) {
            if(user.token){
                is_authenticated = true
            }else{

                await axios({
                    method: 'post',
                    url: api.url+'auth/refresh-token',
                    data: {
                        refreshToken : refresh_token
                    }
                })
                    .then(response => {
                        is_authenticated = true;
                        const { access_token, refresh_token, session } = response.data;
                        const updatedUser = jwt_decode(access_token);
                        const isSuperAdmin = updatedUser.realm_access.roles.find(x => x === 'super_admin') === 'super_admin';
                        localforage.setItem('refresh_token', refresh_token);
                        updateUser =
                            {
                                token: access_token,
                                locale: updatedUser.locale,
                                firstname: updatedUser.given_name,
                                lastname: updatedUser.family_name,
                                email: updatedUser.email,
                                refresh_token,
                                isAuthenticated : true,
                                organisation_id: updatedUser.organisationId,
                                isSuperAdmin
                            }

                        _validateSession(session);

                    })
                    .catch(error => {
                        console.log({status: error.response, error: error.response})
                    })
            }
        }

    }

    if(!is_authenticated){
        window.location="/user";
    }else{
        return updateUser;
    }
};

const _validateSession = async (session) => {

    const { message, action } = session;

    switch (action){
        case 'user.invalid':
            window.location="/user/login?message="+message
            break;
        case 'user.noOrganisation':
            // Todo redirect to organisation creation
            //setOrganisation(data);
            break;
        default:
            await _setOrganisation(session);
    }
}

const _setOrganisation = async (data) => {
    if(data){
        await setOrganisationState(data.current,data.available,data.permissions,data.languages,data.key_access,data.user_key_access)
    }else{
        window.location="/user/login";
    }
    coreModuleLoaded('authValidation');
}