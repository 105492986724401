import locale_en from './locales/en_US';
import locale_fr from './locales/fr_CA';

const locales = [
    {
        key: 'fr',
        name: 'Français',
        flag: 'fr'
    },
    {
        key: 'en',
        name: 'English',
        flag: 'gb'
    },
]

export {locale_fr, locale_en, locales};