import React, {useEffect, useState, Fragment} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl'
import {Button, CloseButton, Col, Form, Modal, Row} from "react-bootstrap";
import apiCalls from "../apiCalls";
import 'assets/components/jsoneditor.scss';
import { JsonEditor as Editor } from 'jsoneditor-react';
import {Dropdown} from "../../components/Forms";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {loadMessagesAsync} from "../../redux/slices/message";
import {useDispatch} from "react-redux";

const initLocale = {
    fr: "",
    en: "",
}

const LabelModal = ({show, setShow, id, creationMode = false, intl}) => {
    const [data, setData] = useState([]);
    const [isNew, setIsNew] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [editor, setEditor] = useState(false);
    const [locales, setLocales] = useState([]);
    const [inputValue, setInputValue] = useState({id:0,localeGroups:'',label:''});
    const dispatch = useDispatch();

    useEffect(()=>{
        if(show){
            if(!creationMode){
                const ids = id.split('.');
                setInputValue({
                    id: 0,
                    localeGroups: ids[0],
                    label: ids[1]
                })
                loadData();
            }else{
                setIsLoading(false);
                initEditor(initLocale);
            }

        }else{
            setEditor(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[show]);

    useEffect(() => {
        setInputValue({
            ...inputValue,
            id: data.id
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data]);

    const initEditor = (loc) => {
        setLocales(loc);
        setEditor(<Editor
            value={loc}
            onChange={handleChange}
        />)
    }

    const loadData = async () => {
        if(show){
            await apiCalls.get('/core/locals/get/'+id)
                .then( res => {
                    const {data} = res;
                    setData(data);
                    if(!data.add){
                        setIsNew(false);
                        initEditor({
                            ...initLocale,
                            ...data.locales
                        });
                    }else{
                        if(data.status === 500){
                            NotificationManager.error(null, intl.formatMessage({id: "err.badLabelFormat"}), 5000);
                            handleClose();
                        }else{
                            initEditor(initLocale);
                        }

                    }
                    setIsLoading(false);
                })
                .catch( err => { console.log(err) });
        }
    }

    const handleClose = () => {
        setShow(false);
        setIsLoading(true);
    }

    const handleSave = async () => {
        let success = false;
        console.log(locales);
        const {localeGroups, label} = inputValue;
        if(localeGroups.length > 1 && label.length >= 2){
            const name = `${localeGroups}.${label}`;

            await apiCalls.put('/core/locals/add', {
                id: inputValue.id ? inputValue.id : 0,
                name: name,
                name_locale: locales}
            )
                .then( res => {
                    const {data} = res;
                    success = data;

                })
                .catch( err => { console.log(err) });

            if(success){
                NotificationManager.success(null, intl.formatMessage({id: "form.saveSuccess"}), 3000);
                setShow(false);
                dispatch(await loadMessagesAsync());
            }else{
                NotificationManager.error(null, intl.formatMessage({id: "form.saveError"}), 3000);
            }

        }else{
            NotificationManager.error(null, intl.formatMessage({id: "form.fieldRequired"}), 3000);
        }
    }

    const handleChange = (e) => {
        setLocales(e);
    }

    const handleLabelChange = (e) => {
        const { value,name } = e.currentTarget;
        setInputValue({
            ...inputValue,
            [name]: value
        });
    }
    const handleGroupChange = (name, value) => {
        console.log(name,value);
        setInputValue({
            ...inputValue,
            [name]: value
        });
    }

    return <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
            <Modal.Title>{!isNew ? 'Edit' : 'Add'} {id} </Modal.Title>
            <CloseButton variant="white" className="btn" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
            {isLoading ? 'Loading...': <Fragment>
                <NotificationContainer/>
                <Row>
                    <Col md={6}>
                        <Dropdown
                            disabled={!creationMode}
                            list="localeGroups"
                            name="localeGroups"
                            value={inputValue.localeGroups}
                            onChange={handleGroupChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formName" className="has-float-label">
                            <Form.Label><FormattedMessage id="form.label" /></Form.Label>
                            <Form.Control
                                disabled={!creationMode}
                                name="label"
                                type="text"
                                value={inputValue.label}
                                onChange={handleLabelChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {editor}
                    </Col>
                </Row>
            </Fragment>}

        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cancel
            </Button>
            <Button variant="primary" onClick={handleSave}>
                Save
            </Button>
        </Modal.Footer>
    </Modal>;
}
export default injectIntl(LabelModal);