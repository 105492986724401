const forms = {
    "form.save": "Sauvegarder",
    "form.fieldRequired": "Ce champ est obligatoire",
    "form.active": "Actif",
    "form.inactive": "Inactif",
    "form.saveSuccess": "Enregistré avec succès !",
    "form.saveError": "Oups! Une erreur est survenue!",

    "form.timezone": "Fuseaux horaire",
    "form.currency": "Devise",
    "form.companyName": "Nom de l'organisation",
    "form.default_timezone": "Fuseaux horaire par défaut",
    "form.default_currency": "Devise par défaut",
    "form.default_language": "Language par défaut",
};
export default forms;