const user = {
    "user.signInToYourAccount": 'Connectez-vous à votre compte',
    "user.emailAddress": 'Adresse courriel',
    "user.password": 'Mot passe',
    "user.forgotPassword": 'Mot de passe oublié ?',
    "user.enterEmailAddress": 'Inscrivez votre adresse courriel',
    "user.enterPassword": 'Inscrivez votre mot passe',
    "user.signIn": 'Connexion',
    "user.validation1" : "*Doit contenir au minimum 2 caractères",
    "user.validation2" : "*Ne peut pas être plus long que 100 caractères",
    "user.validation3" : "*Le mot de passe est obligatoire",
    "user.validation4" : "*Doit être une adresse courriel valide",
    "user.validation5" : "*Ne peut pas être plus long que 100 caractères",
    "user.validation6" : "*L'adresse courriel est obligatoire",
    "user.invalid_grant" : "Les informations de connexions ne sont pas valides",
    "user.loginFailed" : "Erreur de connexion",
    "user.noAccountYet" : "Vous n'avez pas encore de compte ?",
    "user.signUpNow" : "S'inscrire maintenant.",
};
export default user;