import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import coreReducer from './slices/core';
import interfaceReducer from './slices/interface';
import messageReducer from "./slices/message";
import orgReducer from "./slices/organisation";
import cfReducer from "./slices/centralforce";
import scrollReducer from "./slices/scroll";

export default configureStore({
    reducer: {
        auth: authReducer,
        core: coreReducer,
        int: interfaceReducer,
        messages: messageReducer,
        org: orgReducer,
        cf: cfReducer,
        scroll: scrollReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['scroll/setScroll'],
                ignoredPaths: ['scroll'],
            },
        }),
    }
);