import React from "react";
import {Form} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {ExoLabel} from "helpers";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';

const PhoneInput = ({
                        value, formikProps, label, className='mb-3', name,
                        defaultCountry = null
}) =>  {

    const {errors, touched, setFieldValue} = formikProps;
    const onSelectFlag = (phone, data) =>{
        const {dialCode, iso2} = data
        if(phone){
            setFieldValue([name+'_data'], {dialCode, iso2})
        }
    }
    const onPhoneNumberChange = (valid,phone) =>{
        setFieldValue(name, phone)
    }
    const onPhoneNumberBlur = (valid,phone,data) =>{
        onSelectFlag(phone, data);
        onPhoneNumberChange(valid,phone);
    }

    return (
        <Form.Group controlId={name} className={className + " has-float-label"}>
            <Form.Label><ExoLabel id={label ? label : "form."+name} /></Form.Label>

            <IntlTelInput
                inputClassName="form-control"
                preferredCountries={['ca','us']}
                defaultValue={value || ''}
                defaultCountry={defaultCountry}
                onSelectFlag={(phone,data)=>onSelectFlag(phone, data)}
                onPhoneNumberChange={(valid,phone)=>onPhoneNumberChange(valid,phone)}
                onPhoneNumberBlur={(valid,phone, data)=>onPhoneNumberBlur(valid,phone,data)}
                format
                fieldName={name}
            />

            {touched[value] && errors[value] ? (
                <div className="error-message">{errors[value]}</div>
            ): null }
        </Form.Group>
    )
}
export default injectIntl(PhoneInput);