import React, {useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { IntlProvider } from 'react-intl';
import { locale_fr, locale_en } from './intl'
import { useSelector, useDispatch } from 'react-redux';
import {setInterface} from "./redux/slices/interface";
import { loadMessagesAsync, setMessages } from './redux/slices/message';
import localforage from "localforage";
import {useHotkeys} from "react-hotkeys-hook";
import {LabelEditor} from "./helpers/labels";


const ViewMain = React.lazy(() =>
    import(/* webpackChunkName: "views" */ './views')
);
const ViewApp = React.lazy(() =>
    import(/* webpackChunkName: "views-app" */ './views/app')
);
const ViewUser = React.lazy(() =>
    import(/* webpackChunkName: "views-user" */ './views/user')
);
const ViewCF = React.lazy(() =>
    import(/* webpackChunkName: "views-centralforce" */ './views/centralforce')
);
const ViewPublic = React.lazy(() =>
    import(/* webpackChunkName: "views-user" */ './views/public')
);

const staticMessages = {
  fr : locale_fr,
  en : locale_en,
}

const App = () => {

    const dispatch = useDispatch();
    const int = useSelector((state) => state.int);
    const messages = useSelector((state) => state.messages)
    const [locale, setLocale] = useState('en'); //Fallback language
    const params = new URLSearchParams(document.location.search.substring(1));
    const [showEditor,setShowEditor] = useState(false);

    useHotkeys('shift+ctrl+l', () => {
        setShowEditor(true)
    });

    useEffect(()=>{
        loadMessages().then();
        setLanguage().then();
        setDebugMode();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const setLanguage = async () => {
        let currentLocal;
        let currentInterface;
        const language = navigator.language.split(/[-_]/)[0];

        try {
            //Je valide si une langue est présente dans le storage local
            try {
                currentInterface = await localforage.getItem('interface');
                currentInterface = {
                    ...int,
                    ...currentInterface
                }
                currentLocal = currentInterface.locale;
            } catch (err) {
                currentLocal = locale;
                currentInterface = int;
                console.log(err);
            }
        } catch {
            //Sinon je valide si la langue du navigateur existe dans les locales
            currentLocal = messages[language] ? language : locale;
        }

        setLocale(currentLocal);
        const debugEnabled = await localforage.getItem('debug');

        const root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
        root.className = currentInterface.theme;

        dispatch(
            setInterface({
                ...currentInterface,
                locale: currentLocal,
                debugEnabled
            })
        );
    }

    const loadMessages = async () => {

        try {
            let locales = await localforage.getItem('locales');

            if(!locales){
                localforage.setItem('locales', staticMessages).then(function () {
                    locales = staticMessages;
                }).catch(function(err) {
                    console.log(err);
                });
            }
            dispatch(setMessages(locales))
        } catch (err) {
            console.log(err);
        }

        dispatch(loadMessagesAsync(null));

    }

    const setDebugMode = () => {
        const debug = params.get('debug');
        if(debug === 'enable'){
            localforage.setItem('debug', true);
        }
        if(debug === 'disable'){
            localforage.removeItem('debug');
        }
    }

  return (
      <IntlProvider
          locale={locale}
          messages={messages[int.locale]}
          onError={()=>{return true}}
      >
          <Router>
              <LabelEditor showEditor={showEditor} setShowEditor={setShowEditor} />
            <Switch>
              <Route exact path="/"
                     render={props => <ViewMain {...props} />} />
              <Route
                  path="/user"
                  render={props => <ViewUser {...props} />}
              />
                <Route
                    path="/centralforce/:token/:custid/:locale"
                    render={props => <ViewCF {...props} />}
                />
                <Route
                    path="/centralforce"
                    render={props => <ViewCF {...props} />}
                />
                <Route
                    path="/public"
                    render={props => <ViewPublic {...props} />}
                />
              <Route
                  path="/app"
                  render={props => <ViewApp {...props} />}
              />
            </Switch>
          </Router>
      </IntlProvider>
  );
}

export default App