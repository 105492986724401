import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    appLoaded: false,
    modules: {
        assets: false,
        interface: false,
        organisation: true,
        locales: false,
        authValidation: false,
    }
}

export const coreSlice = createSlice({
    name: 'core',
    initialState,
    reducers: {
        setCore: (state, action) => {
            if(action.payload){
                return {
                    ...initialState,
                    ...action.payload
                }
            }
        },

    }
});


export const { setCore } = coreSlice.actions;

export default coreSlice.reducer;