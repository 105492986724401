const muit = {
    body: {
        emptyDataSourceMessage: "Pas d'enregistrent à afficher",
        addTooltip: "Ajouter",
        deleteTooltip: "Supprimer",
        editTooltip: "Editer",
        filterRow: {
            filterTooltip: "Filtrer"
        },
        editRow: {
            deleteText: "Voulez-vous supprimer cette ligne?",
            cancelTooltip: "Annuler",
            saveTooltip: "Enregistrer"
        }
    },
    grouping: {
        placeholder: "Tirer l'entête ...",
        groupedBy: "Grouper par:"
    },
    header: {
        actions: "Actions"
    },
    pagination: {
        labelDisplayedRows: "{from}-{to} de {count}",
        labelRowsSelect: "lignes",
        labelRowsPerPage: "lignes par page:",
        firstAriaLabel: "Première page",
        firstTooltip: "Première page",
        previousAriaLabel: "Page précédente",
        previousTooltip: "Page précédente",
        nextAriaLabel: "Page suivante",
        nextTooltip: "Page suivante",
        lastAriaLabel: "Dernière page",
        lastTooltip: "Dernière page"
    },
    toolbar: {
        addRemoveColumns: "Ajouter ou supprimer des colonnes",
        nRowsSelected: "{0} ligne(s) sélectionée(s)",
        showColumnsTitle: "Voir les colonnes",
        showColumnsAriaLabel: "Voir les colonnes",
        exportTitle: "Exporter",
        exportAriaLabel: "Exporter",
        exportName: "Exporter en CSV",
        searchTooltip: "Chercher",
        searchPlaceholder: "Chercher"
    }
};
export default muit;