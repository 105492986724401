const user = {
    "user.signInToYourAccount": 'Sign into your account',
    "user.emailAddress": 'Email Address',
    "user.password": 'Password',
    "user.forgotPassword": 'Forgot your password?',
    "user.enterEmailAddress": 'Enter your email address',
    "user.enterPassword": 'Enter your password',
    "user.signIn": 'Sign In',
    "user.validation1" : "*Password must have at least 2 characters",
    "user.validation2" : "*Password can't be longer than 100 characters",
    "user.validation3" : "*Password is required",
    "user.validation4" : "*Must be a valid email address",
    "user.validation5" : "*Email must be less than 100 characters",
    "user.validation6" : "*Email is required",
    "user.invalid_grant" : "Invalid user credentials",
    "user.loginFailed" : "Login Failed",
    "user.noAccountYet" : "Don't have an account yet?",
    "user.signUpNow" : "Sign up now.",
};
export default user;