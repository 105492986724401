import user from './fr_CA/user';
import menu from './fr_CA/menu';
import columns from './fr_CA/columns';
import muit from './fr_CA/material-table';
import commons from "./fr_CA/commons";
import forms from "./fr_CA/forms";
const locale_fr = {
 ...user,
 ...menu,
 ...columns,
 ...commons,
 ...forms,
 muit,
 "core.loggedIn" : "Connecté",
};
export default locale_fr;