import React, {useEffect} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {ExoLabel} from "helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormikInput = (props) =>  {
    const {
        value, type, formikProps, prefix, suffix, min, max, disabled, step, onInput, label, row=3, intl, className = "mb-3",
        hideLabel, onChange, autoFocus
    } = props;

    const {values, errors, touched, handleChange, handleBlur, setFieldValue} = formikProps;

    const formControl = <Form.Control
        autoFocus={autoFocus}
        name={value}
        disabled={disabled}
        onChange={onChange || handleChange}
        onBlur={handleBlur}
        value={values[value]}
        type={type}
        min={min}
        max={max}
        step={step}
        onInput={onInput}
        className={touched[value] && errors[value] ? "error" : null}
    />

    useEffect(()=>{
       //console.log(value, values[value])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])

    if(value){
        switch(type){
            case "hidden" :
                return (
                    <Form.Control
                        name={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[value]}
                        type="hidden"
                    />
                )
            case "checkbox":
            case "switch":
                return (
                    <Form.Group className={className ? className : "mb-3"} controlId={value}>
                        <Form.Check
                            autoFocus={autoFocus}
                            type={type}
                            disabled={disabled}
                            name={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values[value]}
                            checked={values[value]}
                            label={intl.formatMessage({id: label ? label : "form."+value})}
                        />
                        {touched[value] && errors[value] ? (
                            <div className="error-message">{errors[value]}</div>
                        ): null }
                    </Form.Group>
                );
            case "textarea" :
                return (
                    <Form.Group controlId={value} className={className + " has-float-label"}>
                        {!hideLabel ? <Form.Label><ExoLabel id={label ? label : "form."+value} /></Form.Label> : ''}
                        <Form.Control
                            autoFocus={autoFocus}
                            name={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values[value]}
                            as="textarea" rows={row}
                            className={touched[value] && errors[value] ? "error" : null}
                        />
                        {touched[value] && errors[value] ? (
                            <div className="error-message">{errors[value]}</div>
                        ): null }
                    </Form.Group>
                )
            case "datePicker" :
                return (
                    <Form.Group controlId={value} className={className + " has-float-label"}>
                        {!hideLabel ? <Form.Label><ExoLabel id={label ? label : "form."+value} /></Form.Label> : ''}
                        <DatePicker
                            autoFocus={autoFocus}
                            name={value}
                            disabled={disabled}
                            dateFormat={props.dateFormat || "yyyy-MM-dd"}
                            minDate={props.minDate}
                            maxDate={props.maxDate}
                            selected={values[value]}
                            onChange={(date)=>setFieldValue(value, date)}
                            className={touched[value] && errors[value] ? "form-control error" : "form-control"}
                        />
                        {touched[value] && errors[value] ? (
                            <div className="error-message">{errors[value]}</div>
                        ): null }
                    </Form.Group>
                )
            default:
                return(
                    <Form.Group controlId={value} className={className + " has-float-label"}>
                        {!hideLabel ? <Form.Label><ExoLabel id={label ? label : "form."+value} /></Form.Label> : ''}

                        {prefix || suffix ?
                            <InputGroup>
                                {prefix ? <InputGroup.Text>{prefix}</InputGroup.Text> : '' }
                                    {formControl}
                                {suffix ? <InputGroup.Text>{suffix}</InputGroup.Text> : '' }
                            </InputGroup>
                            : formControl
                        }

                        {touched[value] && errors[value] ? (
                            <div className="error-message">{errors[value]}</div>
                        ): null }
                    </Form.Group>
                )
        }
    }
}
export default injectIntl(FormikInput);