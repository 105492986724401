
export const formatDate = (date, locale='fr-CA',style='short') => {
    if(date){
        try{
            return new Intl.DateTimeFormat(locale, { dateStyle: style}).format( new Date(date));
        }catch(e){
            return '';
        }
    }else{
        return '';
    }
}
export const formatDateTime = (date, locale='fr-CA',style='short') => {
    if(date){
        try{
            return new Intl.DateTimeFormat(locale, { dateStyle: style, timeStyle: 'short'}).format( new Date(date));
        }catch(e){
            return '';
        }
    }else{
        return '';
    }
}

export const formatWeekNumber = (date, addYear) => {
    if(date){
        try{
            const currentDate = new Date(date);
            const year = new Date(currentDate.getFullYear(), 0, 1);
            const days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));
            const week = Math.ceil( days / 7) ;

            if(addYear){
                return `${currentDate.getFullYear()}-${week}`
            }
            else{
                return week
            }
        }catch(e){
            return '';
        }
    }else{
        return '';
    }
}

export const formatToDate = (date) => {
    try{
        return new Date(date);
    }catch(e){
        return null;
    }
}

export const formatCurrency = (amount, locale='fr-CA',currency='CAD') => {
    try{
        return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount);
    }catch(e){
        return new Intl.NumberFormat(locale, { style: 'currency', currency: currency || 'CAD' }).format(0)
    }

}

export const formatNumber = (number, locale='fr-CA',digits = 2) => {
    try{
        return new Intl.NumberFormat(locale, { minimumFractionDigits: digits, maximumFractionDigits: digits }).format(number)
    }catch(e){
        return 0;
    }
}

const getRandomElement = arr => {
    const rand = Math.floor(Math.random() * arr.length);
    return arr[rand];
}
export const generateRandomPassword = (length, maxUpper = 2, maxNumber = 2) => {
    const uppercase = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const lowercase = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const special = ['!', '@', '$', '&', '?'];
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    let password = '';

    for (let i = 0; i < length; i++) {
        let includes = lowercase

        if(password.replace(/[^A-Z]/g, '').length < maxUpper){
            includes = [...includes, ...uppercase]
        }
        if(password.replace(/[^0-9]/g, '').length < maxNumber){
            includes = [...includes, ...numbers]
        }
        if(password.length < length - 1){
            password += getRandomElement(includes);
        }else{
            password += getRandomElement(special);
        }
    }

    return password;
}

