const forms = {
    "form.save": "Save",
    "form.fieldRequired": "This field is required",
    "form.active": "Active",
    "form.inactive": "Inactive",
    "form.saveSuccess": "Saved successfully",
    "form.saveError": "Oups! Something went wrong!",

    "form.timezone": "Timezone",
    "form.currency": "Currency",
    "form.companyName": "Organisation Name",
    "form.default_timezone": "Default Timezone",
    "form.default_currency": "Default Currency",
    "form.default_language": "Default Language",
};
export default forms;