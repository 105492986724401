import {useDebug} from "hooks";
import {useSelector} from "react-redux";

const LocaleLabel = ({id, inl, className}) => {
    const debug = useDebug();
    const messages = useSelector((state) => state.messages);

    try{
        return <span className={className}>{messages[inl.code][id] || id}</span>
    }catch (e){
        if(debug) console.log('Debug', e)
        return ''
    }

}
export default LocaleLabel;