import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {login} from '../../helpers/authUtils'

const initialState = {
    token: null,
    isAuthenticated: false,
    firstname: '',
    lastname: '',
    email: '',
    locale: 'en',
    login_loading: false,
    login_status: null,
    login_message: '',
    organisation_id: null,
    isSuperAdmin: false
}

export const loginAsync = createAsyncThunk(
    'auth/loginAsync',
    async (payload, { rejectWithValue }) => {
        try {
            return await login(payload);
        } catch (err) {
            return rejectWithValue([], err);
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginStatus: (state, action) => {
            const {login_status, login_message} = action.payload;
            return {
                ...state,
                login_status,
                login_message
            }
        },
        setLoginLoading: (state, action) => {
            const login_loading = action.payload;
            return {
                ...state,
                login_loading
            }
        },
        setAuthSession: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },

    },
    extraReducers: {
        [loginAsync.fulfilled]: (state, action) => {
            const {payload} = action;
            if(payload.status === 200){
                return {
                    ...state,
                    ...payload.data,
                    isAuthenticated : true,
                    login_status: payload.status,
                    login_loading: false
                }
            }
            if(payload.status === 401){
                return {
                    ...initialState,
                    login_status: payload.status,
                    login_message: payload.error
                }
            }
        },
        [loginAsync.rejected]: (state, action) => {
            console.log(action);
        },
    },
});


export const { setAuthSession, setLoginLoading, setLoginStatus } = authSlice.actions;

export default authSlice.reducer;