import store from 'redux/store';
import {setCore} from "redux/slices/core";
import {setOrganisation} from "redux/slices/organisation";
import apiCalls from "./apiCalls";

export const coreModuleLoaded = (name) => {
    const core = store.getState().core;
    store.dispatch(setCore({
        ...core,
        modules: {
            ...core.modules,
            [name]: true
        }
    }))
};

export const switchOrganisation = async (organisation_id) => {
    const core = store.getState().core;
    store.dispatch(setCore({
        appLoaded: false,
        modules: {
            ...core.modules,
            organisation: false,
            authValidation: false,
            assets: false
        }
    }));

    await apiCalls.put('/user/switch_organisation', {organisation_id})
        .then( res => {
            const {data} = res;
            const {action} = data;
            switch (action) {
                case 'user.badOrganisation':
                    coreModuleLoaded('organisation');
                    coreModuleLoaded('authValidation');
                    break;
                case 'success':
                    coreModuleLoaded('organisation');
                    window.location="/";
                    break;
                default:
                    return false;
            }
        })
        .catch( err => { console.log(err) });

};

export const setOrganisationState = (current,available,permissions,languages,key_access,user_key_access) => {
    const is_unique = available.length === 1;
    const org = store.getState().org;

    store.dispatch(setOrganisation({
        ...org,
        id: current.orgid,
        name: current.name,
        key: current.key,
        available: !is_unique ? available : false,
        user_roles: [],
        user_access: permissions,
        user_key_access,
        key_access,
        settings: current.settings,
        currency: current.default_currency,
        language: current.default_language,
        languages,
        timezone: current.default_timezone,
        country: current.country_code,
        logo_url: current.logo_url
    }))

}