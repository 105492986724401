import axios from 'axios';
import {api} from 'config/config'
import store from 'redux/store'

const instance = axios.create({
    baseURL: api.url
});
instance.CancelToken = axios.CancelToken;

instance.interceptors.request.use(
    async config => {
        let token = await store.getState().auth.token;
        if(!token){
            token = await store.getState().cf.token
        }
        config.headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

instance.interceptors.response.use(response => {
    return response;

}, error => {

    const res = error.response;
    if(res.status === 401){
        //localforage.removeItem('refresh_token');
        window.location = `/user/login?message=${res.data.message}`;
    }
    if(res.status === 403){
        //todo : redirect to Unauthorized component
        console.log("You are not authorized to view this content");
        return 403;
    }
    if(res.status === 500){
        //todo : Message popup
        console.log('HELLO',error);
        return 500;
    }
    return Promise.reject(error);

});

export default instance;