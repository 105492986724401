import user from './en_US/user';
import menu from "./en_US/menu";
import commons from "./en_US/commons";
import forms from "./en_US/forms";
const locale_en = {
  ...user,
  ...menu,
  ...commons,
  ...forms,
  muit: [],
  "core.loggedIn" : "Logged In",
};
export default locale_en;

