import React, {useState, useEffect} from "react";
import {Form} from "react-bootstrap";
import { ExoLabel } from "helpers";
import Select from 'react-select';
import {loadList} from "./utils";

/*
Options
onChange() : callback function
list : nom de la liste (call ajax api - dropdown.js
value : item sélectionné.
translate BOOLEAN : Traduit les labels si TRUE
 */

const Dropdown = ({
                      onChange, list, name, value, firstEmpty = true,
                      disabled = false, groupClassName='mb-3', className='mb-3 mt-3', label = false,
                      query='', customList=false, formikProps,
                      forceReset = false, hideLabel
}) =>  {

    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState(value);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setInputValue('')
        if(query !== 'standalone'){
            loadList(list,setIsLoaded,setOptions,firstEmpty,query,customList).then();
        }else{
            setOptions(list);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        if(forceReset){
            const {reset, setReset} = forceReset;
            if(reset){
                setInputValue('');
                setReset(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReset]);

    useEffect(() => {
        if(value){
            if(value !== inputValue.value && isLoaded) {
                const selectedValue = options.find( ({key}) => key === value );
                setInputValue(selectedValue);
            }
        }else{
            setInputValue(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, isLoaded])

    const handleChange = (e) => {
        onChange(name, e.key, e.value);
        setInputValue(e);
    }

    return (
        <Form.Group controlId={`form${name}`} className={"has-float-label " + groupClassName}>
            {!hideLabel ? <Form.Label><ExoLabel id={label ? label : `form.${name}`}/></Form.Label> : ''}

            <Select
                isDisabled={disabled}
                className={"react-select " + className +
                    (formikProps ?
                        formikProps.touched[name] && formikProps.errors[name] ? " error" : null
                        : null
                    )
                }
                classNamePrefix="react-select"
                options={options}
                onChange={handleChange}
                name={name}
                value={inputValue}
            />
            {formikProps ? formikProps.touched[name] && formikProps.errors[name] ? (
                <div className="error-message">{formikProps.errors[name]}</div>
            ): null : null }
        </Form.Group>
    )

}
export default Dropdown;