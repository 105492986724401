import { createSlice } from '@reduxjs/toolkit';

const initialState = {}
export const scrollSlice = createSlice({
    name: 'scroll',
    initialState,
    reducers: {
        setScroll: (state, action) => {
            if(action.payload){
                return {
                    ...initialState,
                    ...action.payload
                }
            }
        },

    }
});

export const { setScroll } = scrollSlice.actions;

export default scrollSlice.reducer;