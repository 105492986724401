import { createSlice } from '@reduxjs/toolkit';
import localforage from "localforage";

const initialState = {
    sidebarCollapsed: false,
    locale: 'en',
    currentPath: [],
    debugEnabled: false,
    theme: 'dark',
    scroll: {},
}

export const interfaceSlice = createSlice({
    name: 'int',
    initialState,
    reducers: {
        setInterface: (state, action) => {
            if(action.payload){
                localforage.setItem('interface', action.payload);
                return {
                    ...initialState,
                    ...action.payload
                }
            }
        },

    }
});


export const { setInterface } = interfaceSlice.actions;

export default interfaceSlice.reducer;