import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from "./redux/store";
import './assets/custom.scss'
import 'react-notifications/lib/notifications.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import localforage from "localforage";

localforage.config({
    name: 'ExoStack'
});

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<div className="is_loading" />}>
            <App />
        </Suspense>
    </Provider>,
  document.getElementById('root')
);

//serviceWorkerRegistration.unregister();
//serviceWorkerRegistration.register();
reportWebVitals(); //reportWebVitals(console.log); for performance test

