const menu = {
    "menu.dashboard": "Tableau de bord",
    "menu.sales": "Ventes",
    "menu.customers": "Clients",
    "menu.salesorder": "Commandes",
    "menu.invoicing": "Facturation",
    "menu.app": "Accueil",
    "menu.core": "Paramètres système",
    "menu.organisations": "Organisations",
    "menu.edit": "Modifications",
    "menu.add": "Ajouter",
    "menu.view": "Visualiser",
    "menu.users": "Utilisateurs",
};
export default menu;