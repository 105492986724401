import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {loadMessages} from '../../helpers/labels'
import localforage from "localforage";

const initialState = {
    ab : [], aa : [], af : [], ak : [], sq : [], am : [], ar : [], an : [], hy : [], as : [],
    av : [], ae : [], ay : [], az : [], bm : [], ba : [], eu : [], be : [], bn : [], bh : [],
    bi : [], bs : [], br : [], bg : [], my : [], ca : [], ch : [], ce : [], ny : [], zh : [],
    cv : [], kw : [], co : [], cr : [], hr : [], cs : [], da : [], dv : [], nl : [], dz : [],
    en : [], eo : [], et : [], ee : [], fo : [], fj : [], fi : [], ff : [], gl : [], ka : [],
    de : [], el : [], gn : [], gu : [], ht : [], ha : [], he : [], hz : [], hi : [], ho : [],
    hu : [], ia : [], id : [], ie : [], ga : [], ig : [], ik : [], io : [], is : [], it : [],
    iu : [], ja : [], jv : [], kl : [], kn : [], kr : [], ks : [], kk : [], km : [], ki : [],
    rw : [], ky : [], kv : [], kg : [], ko : [], ku : [], kj : [], la : [], lb : [], lg : [],
    li : [], ln : [], lo : [], lt : [], lu : [], lv : [], gv : [], mk : [], mg : [], ms : [],
    ml : [], mt : [], mi : [], mr : [], mh : [], mn : [], na : [], nv : [], nb : [], nd : [],
    ne : [], ng : [], nn : [], no : [], ii : [], nr : [], oc : [], oj : [], cu : [], om : [],
    or : [], os : [], pa : [], pi : [], fa : [], pl : [], ps : [], pt : [], qu : [], rm : [],
    rn : [], ro : [], ru : [], sa : [], sc : [], sd : [], se : [], sm : [], sg : [], sr : [],
    gd : [], sn : [], si : [], sk : [], sl : [], so : [], st : [], es : [], su : [], sw : [],
    ss : [], sv : [], ta : [], te : [], tg : [], th : [], ti : [], bo : [], tk : [], tl : [],
    tn : [], to : [], tr : [], ts : [], tt : [], tw : [], ty : [], ug : [], uk : [], ur : [],
    uz : [], ve : [], vi : [], vo : [], wa : [], cy : [], wo : [], fy : [], xh : [], yi : [],
    yo : [], za : [], zu : [], fr : [],
}

export const loadMessagesAsync = createAsyncThunk(
    'messages/loadMessagesAsync',
    async (payload, { rejectWithValue }) => {
        try {
            return await loadMessages();
        } catch (err) {
            return rejectWithValue([], err);
        }
    }
);

export const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setMessages: (state, action) => {
            const {payload} = action;
            return {
                ...state,
                ...payload
            }
        },
    },
    extraReducers: {
        [loadMessagesAsync.fulfilled]: (state, action) => {
            const {payload} = action;
            if(payload.status === 200){
                localforage.setItem("locales", payload.data);
                return {
                    ...state,
                    ...payload.data
                }
            }
            if(payload.status === 401){
                return {
                    ...initialState,
                }
            }
        },
        [loadMessagesAsync.rejected]: (state, action) => {
            console.log(action);
        },
    },
});


export const { setMessages } = messageSlice.actions;

export default messageSlice.reducer;