import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export const useDebug = () => {
    const [debug,setDebug] = useState(false);
    const auth = useSelector((state) => state.auth);
    const int = useSelector((state) => state.int);

    useEffect(()=>{
        if(auth.isSuperAdmin){
            setDebug(int.debugEnabled);
        }
    },[auth, int])

    return debug;
}