import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null,
    sub: null,
    custid: false,
    customer: {},
    locale: 'en',
    organisation_id: null,
}

export const cfSlice = createSlice({
    name: 'cf',
    initialState,
    reducers: {
        setCF: (state, action) => {
            if(action.payload){
                return {
                    ...initialState,
                    ...action.payload
                }
            }
        },

    }
});


export const { setCF } = cfSlice.actions;

export default cfSlice.reducer;