import localforage from "localforage";
import apiCalls from "helpers/apiCalls";


const loadList = async (list,setIsLoaded,setOptions,firstEmpty,query='',customList=false) => {
    let locale;
    try {
        const value = await localforage.getItem('interface');
        locale = value.locale;
    } catch (err) {
        locale = 'en';
        console.log(err);
    }

    let defaultOptions = firstEmpty ? [{key:0,value:0,label:' '}] : [];
    let uri = `/commons/dropdown/${list}?locale=${locale}${query}`
    if(customList){
        uri = `${customList}${customList.indexOf('?')!==-1 ? '&' : "?"}${customList.indexOf('locale')!==-1 ? '' : `locale=${locale}`}${query}`
    }

    const options = await apiCalls.get(uri)
        .then( res => {
            if(res.data.length > 0){
                defaultOptions.push(...res.data);
            }
            setOptions(defaultOptions)
            return defaultOptions
        })
        .catch( err => {
            console.log(err)
        });
    setIsLoaded(true);
    return options;
};

export {
    loadList
};