import {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import store from "redux/store";

/*
Check if organisation has access to a specific view
Usage :
        <WithAccess access_key="<KEY>">
            // Content within the access ...
            // Will render only if organisation as access
        </WithAccess>
 */
export const WithAccess = ({access_key, children}) => {
    const org = useSelector(state => state.org)
    const [isValid, setIsValid] = useState(false)

    useEffect(()=>{
        if(org){
            setIsValid(org.key_access.find(e => e === access_key));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[org])

    return !isValid ? '' : children
}

/*
Check if organisation has access to a specific view
Usage : withAccess("<KEY>") will return true or false
 */
export const withAccess = (access_key) => {
    const org = store.getState().org;
    return !!org.key_access.find(e => e === access_key);
}

/*
Check if a user has access to a specific view
Usage : hasAccess("<KEY>") will return true or false
 */
export const hasAccess = (access_key) => {
    const org = store.getState().org;
    return !!org.user_key_access.find(e => e === access_key);
}

/*
Conditional with organisation key
Typically unique for a specific organisation.
Usage : withKey("<KEY>") will return true or false
 */
export const withKey = (key) => {
    const org = store.getState().org;
    return org.key === key;
}