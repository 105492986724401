import React, {useState, useEffect} from "react";
import {Form} from "react-bootstrap";
import {injectIntl} from 'react-intl'
import { ExoLabel } from "helpers";
import Select from 'react-select';
import {loadList} from "./utils"

/*
Options
onChange() : callback function
list : nom de la liste (call ajax api - dropdown.js
value : item sélectionné.
translate BOOLEAN : Traduit les labels si TRUE
 */

const MultiSelect = ({ onChange, list, name, value,
                        firstEmpty = true, disabled = false, groupClassName='mb-3', className='mb-3',
                        handleChangeWarning = false, label = false, query='', customList=false
                    }) =>  {

    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        loadList(list,setIsLoaded,setOptions,firstEmpty,query,customList).then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(value !== inputValue.value && isLoaded) {
            let selectedValues = []
            if(typeof value === 'object'){
                value.map( item =>{
                    selectedValues.push(options.find( ({key}) => key === item ))
                    return true;
                })
            }
            setInputValue(selectedValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, isLoaded])


    const handleChange = (e) => {
        let list = []
        e.map(item=>{
            list.push(item.key);
            return true;
        })
        onChange(name, list);
        setInputValue(e);
        if(handleChangeWarning){
            handleChangeWarning(list);
        }
    }

    return (
        <Form.Group controlId={`form${name}`} className="has-float-label">
            <Form.Label><ExoLabel id={label ? label : `form.${name}`}/></Form.Label>

            <Select
                isMulti
                isDisabled={disabled}
                className={"react-select " + className}
                classNamePrefix="react-select"
                options={options}
                onChange={handleChange}
                name={name}
                value={inputValue}
            />
        </Form.Group>
    )

}
export default injectIntl(MultiSelect);