import {apiCalls} from "./index";

export const downloadFile = async (uuid, asURL = true) => {

    await apiCalls.get('/commons/media/file/'+uuid+'?asURL='+asURL)
        .then( res => {
            const { data } = res
            if(data.status === 200){
                const {url} = data.data;
                const link = document.createElement("a");
                link.href = url;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                console.log(data)
            }

        })
        .catch( err => { console.log(err) });

}