import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl'
import apiCalls from "./apiCalls";
import {useDebug} from "hooks";
import LabelModal from "./components/labelModal";
import {coreModuleLoaded} from "./core";

const ExoLabel = ({id, className=''}) => {
    const [show, setShow] = useState(false);
    const debug = useDebug();

    const editLabel = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShow(true);
    }

    if(id){
        return !debug ?
            <span className={className}><FormattedMessage id={id}/></span> :
            <span className={"label-debug " + className}>
            <FormattedMessage id={id}/>
            <i
                className="edit-label fas fa-spell-check"
                onClick={editLabel}
            />
            <LabelModal show={show} setShow={setShow} id={id}/>
        </span>;
    }else{
        return 'no_data'
    }

}
export default ExoLabel;

export const LabelEditor = ({showEditor, setShowEditor}) => {
    const debug = useDebug();
    return debug && showEditor ? <LabelModal show={showEditor} setShow={setShowEditor} id='' creationMode/> : false;
}


export const loadMessages = async () => {

    const messageAsync = await apiCalls.get('core/locals/loadLocales')
        .then( res => {
            const { data } = res;

            coreModuleLoaded('locales');

            return data;
        })
        .catch( err => { console.log(err) });

    return {status: 200, data : messageAsync}
}