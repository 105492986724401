import React, {useEffect, useState} from "react";
import {Card, Form} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {apiCalls, ExoLabel} from "helpers";
import {useSelector} from "react-redux";

const LocaleInput = ({value, formikProps,
                         name ,fallbackLocale,
                         textarea = false,
                         label, row=3, className='mb-3'}) =>  {
    const request = apiCalls.CancelToken.source();
    const org = useSelector((state) => state.org);
    const auth = useSelector((state) => state.auth);
    const [inputValue, setInputValue] = useState(value);
    const [languages, setLanguages] = useState(false);
    const [language, setLanguage] = useState(false);
    const [labels, setLabels] = useState(false);

    useEffect(()=>{
        if(org.language){
            _handleInputUpdate().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[org])

    useEffect(()=>{
        if(languages){
            let newValues = [];
            languages.map(item=>{
                newValues.push({
                    [item] : value ? value[item] : ''
                });
                return true
            })
            setInputValue(newValues.reduce((r,v)=>({...r, ...v}),{}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])

    const {errors, touched, handleBlur} = formikProps;

    const handleInputChange = (e) => {
        const {target} = e;
        let newValues = {
            ...inputValue,
            [target.name]: target.value
        }

        if(target.name === language){
            formikProps.setFieldValue(fallbackLocale,target.value);
        }
        formikProps.setFieldValue(name,newValues);
        setInputValue(newValues);
    }

    const _handleInputUpdate = async () => {
        setLanguage(org.language);
        setLanguages(org.languages)
        let languageObject = org.languages.map(lang => {
            return value[lang] ? {[lang] : value[lang]} : {[lang] : ''}
        })
        languageObject = languageObject.reduce((r,v)=>({...r, ...v}),{});
        setInputValue(languageObject);
        await loadLabels(org.languages);
    }

    const loadLabels = async (languages) => {
        await apiCalls.post('/core/locals/getLanguagesLabels?locale='+auth.locale,{
            languages
        },{cancelToken: request.token,})
            .then( res => {
                const { data } = res;
                //console.log(data);
                setLabels(data);
            })
            .catch( err => { console.log(err) });
        return () => {
            request.cancel();
        }
    }

    return (
        <div>
            {languages.length > 1 ?
                <Card className="mt-3 mb-3">
                    <Card.Header as="h5" className={"text"}>
                        <ExoLabel id={label ? label : "form."+value} />
                        <i className="fas fa-language pe-2 ps-2 text-primary"/>
                    </Card.Header>
                    <Card.Body>

                    {languages.map(item=>{
                    return <div key={item}>

                            <Form.Group controlId={item} className={className + " has-float-label"}>
                                <Form.Label>
                                    <span className="small">
                                        <i className="fas fa-caret-right pe-2 text-primary"/>
                                        {labels ? labels.find(x => x.code === item).label : item}
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    name={item}
                                    as={textarea ? "textarea" : "input"} rows={row}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    value={inputValue[item]}
                                    className={touched[name] && errors[name] && item === org.language ? "error" : null}
                                />
                                {touched[name] && errors[name] && item === org.language ? (
                                    <div className="error-message">
                                        <ExoLabel id={'err.thisLanguageIsRequired'} />
                                    </div>
                                ): null }
                            </Form.Group>
                        </div>
                    })}

                    </Card.Body>
                </Card>
                :
                <Form.Group controlId={value} className={className + " has-float-label"}>
                    <Form.Label><ExoLabel id={label ? label : "form."+value} /></Form.Label>
                    <Form.Control
                        name={language.toString()}
                        as={textarea ? "textarea" : "input"} rows={row}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        value={inputValue[language]}
                        className={touched[value] && errors[value] ? "error" : null}
                    />
                    {touched[value] && errors[value] ? (
                        <div className="error-message">{errors[value]}</div>
                    ): null }
                </Form.Group> }
        </div>
    )
}
export default injectIntl(LocaleInput);